@charset "UTF-8";
/* CSS Document */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Reset
-------------------------------------------------------------- */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1.4em;
}
caption,
th,
td {
  text-align: left;
  font-weight: 400;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}
blockquote,
q {
  quotes: inherit;
}
a img {
  border: none;
}

/* Micro Clearfix - http://nicolasgallagher.com/micro-clearfix-hack/ */
.cf:before,
.cf:after {
  content: "";
  display: table;
}
.cf:after {
  clear: both;
}
.cf {
  zoom: 1;
  margin: 0.8em 0;
} /* For IE 6/7 (trigger hasLayout) */



/*--- COLORS 
ffffff = white
EDEEF5 = light grey
686977 = grey
9C9FC3 = lavender

102760 = highlight blue
0B1A40 = dark blue 
06112C = dark dark blue

0A1E51 = Text Dark blue

F12BC5 = Pink
F11270 = dark Pink


---*/

/* ---------  BASICS  ----------------------------------------------------- */

/*-------  LAYOUT  ------------------*/
* {
  box-sizing: border-box;
}

body {
  height:100vh;
  padding: 10px;
  margin: 0;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background: #06112C;
  /*background-image: linear-gradient(
    to left top,
    #b91eda,
    #a02ae0,
    #8234e5,
    #5d3be8,
    #1241eb);*/
}


.App{
  display:flex;
  flex-direction:row;
  justify-content:center;
}


.nav-bar{
  height: 60%;
  max-height:600px;
  width: 80px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  border:1px solid #06112C;
  overflow: hidden;
  display:flex;
  flex-direction:column;
  
}

.wrapper {
  margin-left:100px;
  display: flex;
  justify-content:space-between;
  width:90%;
  max-width:1700px;

}

.aside {
  flex:20%;
  padding:0px 0;
  margin:0 2%;
  min-width:280px;
  
}  
.aside h3 {
  margin:40px 0;
  padding: 0 10px;
}

.main{
  margin-bottom:3%; 
  flex:70%;

}

.search-results {
    margin:0 0 30px;
    padding:10px 30px;
    border-radius:30px;
    background:#0B1A40;
    min-height:80px;
    width:98%;
 
        
  }
  .search-details{
    color:#9C9FC3;
    display: -webkit-flex;
    display: flex;
    flex-wrap:wrap;
    justify-content:space-between;
  }

  .result{
    padding: 10px;
  }
  .result h6{
    margin-bottom:8px;
  }
  .result h4{
    color:#fff;
    display:inline-block;
  }




footer {
  flex:auto;
  padding-top: 15px;
}



/*----- TEXT -----*/
h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: 'Poppins', sans-serif;
  line-height: 1;
  font-weight:700;
}
h1 {
  font-size: 54px;
  margin-bottom: 10px;
}
  h1.huge{
    font-size:54px;
    font-weight:700;
    letter-spacing:-2px;
    margin:5px 0 0px;
    line-height:54px;
  }

h2 {
  font-size: 42px;
  margin: 0 0 10px;
  letter-spacing:-2px;
  font-weight:700;
}

h3 {
  font-size: 28px;
  margin-bottom: 10px;
}

h4 {font-size: 18px;}
h5 {font-size: 16px;}
h6{font-size: 12px;}

p {
  line-height: 1.5;
  margin: 0 0 20px;
}

.large {
  font-size: 24px;
}
.small {
  font-size: 10px;
  line-height:.8;
}
.callout {
  font-weight: 700;
  font-style: italic;
  text-align: center;
  border-top: #dfdbcb solid 1px;
  border-bottom: #dfdbcb solid 1px;
  padding: 15px 0 0;
  margin-bottom: 30px;
}

.grey{color:#686977!important;}
/*----- IMAGES & ICONS ----*/

img {
  display:block;
  max-width: 100%;
}

.icon {
  margin:10px auto;
  background:#0B1A40;
  border-radius:15px;
  width:60px;
  height:60px;
  text-align:center;
  padding-top:15px;
  cursor:pointer;

  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.icon:hover{
  background:#102760;
}
.icon:active{
  background:#EDEEF5;
}


.icon-search{
  background:transparent url('../images/icon_search.svg')no-repeat top center;
  width:30px;
  height:40px;
}

.icon-account{
  background: transparent url("../images/icon_account.svg")no-repeat top center;
  width:30px;
  height:40px;
}
.icon-message{
  background: transparent url("../images/icon_msg.svg")no-repeat top center;

  width:30px;
  height:40px;
  vertical-align:middle;
}
.icon-list{
  background: transparent url("../images/icon_list.svg")no-repeat top center;

  width:30px;
  height:40px;
}
.icon-charts{
  background: transparent url("../images/icon_chart.svg")no-repeat top center;

  width:30px;
  height:40px;
}

.icon-msg{
  background: transparent url("../images/icon_msg.svg")no-repeat top left;
  width:20px;
  height:20px;
  margin-right:5px;
}

.icon-call{
  background: transparent url("../images/icon_call.svg")no-repeat top left;
  width:20px;
  height:20px;
  margin-right:5px;
  display:inline-block;
}
.icon-email{
  background: transparent url("../images/icon_mail.svg")no-repeat top left;
  width:20px;
  height:20px;
  margin:0px 5px 0 0;
  display:inline-block;
  
}

.icon.current {background:#102760!important;}

/*------ LINKS  -----------------------------------------------*/
button, .btn{
  background:none;
  border:none;
  cursor: pointer;
  transition: 0.3s;
}
a {
  color: #F12BC5;
  text-decoration: none;
  cursor: pointer;

  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

a:focus,
a:hover {
  color: #fff;
  text-decoration: none;
}
a img:hover {
  border: 4px solid #999;
  text-decoration: none;
}
a:visited {
  color: #F12BC5;
}


.btn-link{
  padding:0;
  color: #F12BC5;
  text-decoration: none;
  text-align:left;
  cursor: pointer;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.btn-link:hover{color:#0B1A40;}

.btn-round {
  background:#fff;
  border:none;
  outline:none;
  border-radius:100%;
  width:36px;
  height:36px;
  padding:6px 10px 6px;
}
.btn-round:hover {
  background: #102760;
  color: #F12BC5;
}
.btn-round:focus {
  background: #F12BC5;
  color:#fff;
} 

/* .btn-close {
  background:#fff;
  color:#F12BC5;
  border:1px solid #F12BC5;
  outline:none;
  border-radius:100%;
  width:40px;
  height:40px;
  text-align:center;
}

.btn-close:hover {
  background: #102760;
  color: #F12BC5;
}
.btn-close:focus {
  background: #F12BC5;
  color:#fff;
} 
.btn-close:visited{color:#000;} */

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #F12BC5;
  background: transparent url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e) center/1em auto no-repeat;
  border: 1px solid #F12BC5;
  border-radius: 50%;
  opacity: 1;
}
.modal-header .btn-close {
  padding: 20px;
  margin:0;
  position:absolute;
  top:30px;
  right: 30px;
}

.btn-primary{
  width:100%;
  margin:10px 0px;
  padding: 8px 20px;  
  color:#F12BC5;
  background:#fff;
  border-radius:30px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  transition: 0.3s;
}
.btn-primary:hover{
  background: #102760;
  color: #F12BC5;
}

.btn-secondary{
  padding:6px 16px;
  color:#F12BC5;
  background:#fff;
  border-color:#F12BC5;
  border-radius:30px;
  font-weight: 700;
  line-height:1;
}
.btn-secondary:hover{
  background: #102760;
  color: #F12BC5;
}

.btn-primary:active, .btn-secondary:active{
  background:#F12BC5;
  color:#0B1A40;
}
.btn-primary:focus, .btn-secondary:focus{
  background:#F12BC5;
  color:#0B1A40;

}


.dealer a {
  display:inline;
  font-size: 12px;
  color:#F12BC5;
  background:#fff;
  text-decoration:none;
}
.dealer a:hover {
  color: #0B1A40;
}
.dealer a:active {
  color: #F11270;
}
.dealer a:visited {
  color: #ccc;
}

/*.pagination a {
  color: #ededed;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border-bottom: #282827;
}
.pagination a.active {
  background-color: #444;
  color: #ccc;
}

.pagination a:hover:not(.active) {
  background-color: #333;
}*/

/*-----------*/


/*-----------   LISTS   -----------------------------------------------*/
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 0px 0;
}

/*-- INPUTs ---*/



input[type="text"],input[type="password"],
input[type="button"]{
  padding: 10px 20px;
  margin: 10px 0 20px;
  display: inline-block;
  box-sizing: border-box;
  border-radius:30px;
  color:#9C9FC3;
}

.input-field {
  width: 100%;
  outline: none;
  border: 2px solid #ffffff;
  background: #EDEEF5;
}
.input-field:focus {
  border: 2px solid #F12BC5;
  background: #ededed;
}

label {
  font-size: 21px;
  font-weight: 300;
}

/*select {
  width: 100%;
  padding: 16px 20px;
  border: none;
  background:#ededed;
  text-transform: uppercase;
}*/
select {
  width: 100%;
  height: 50px;
  padding: 5px 0 5px 20px;
  margin:0;
  cursor: pointer;
  border-radius: 13px;
  border:2px solid #06112C;
  background-color: #0B1A40;
  color:#9C9FC3;
  font-size:16px;
  font-weight: 700;
  outline:none;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
/* For IE <= 11 */
select::-ms-expand {
  display: none; 
}

select:hover{ 
  background-color: #102760;
  border:2px solid #F12BC5;
}
select:focus {
  color: #fff;
  background-color: #102760;
  border:2px solid #F12BC5;
}

select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: var(--select-arrow);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}
select{
background-image: url('../images/caret-down.png');
background-repeat: no-repeat;
background-position: right 0.75rem center;
background-size: 10px 8px;
}


.aside input[type="text"]{
  background-image: url('../images/icon_editText.png');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 10px 10px;
  }
/*option {
  padding:0 30px 0 10px;
  min-height:40px;
  display:flex;
  align-items:center;
  background:#333;
  border-top:#222 solid 1px;
  position:absolute;
  top:0;
  width: 100%;
  pointer-events:none;
  order:2;
  z-index:1;
  transition:.4s ease-in-out;
  box-sizing:border-box;
  overflow:hidden;
  white-space:nowrap;
}*/

/*--- TYPEAHEAD ---*/
.typeahead{}

.rbt {position:relative;}

.aside input[type="text"]{
  margin: 2px 0;
  display: inline-block;
  box-sizing: border-box;
  border-radius:13px; 
  border:2px solid #0B1A40;
  cursor:pointer;
  opacity:1;
}
.aside input[type="text"]:hover{
  /* color: #fff!important; */
  background-color: #102760;
  border:2px solid #F12BC5;
}
.aside input[type="text"]::after{
    content: '';
    position: absolute;
    left: 42%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #e8e8e8;
    clear: both;
}

.aside ::placeholder{color:#9C9FC3;}

.rbt-input-main {
  cursor: text;
  overflow: hidden;
  position: relative;
  font-weight:bold;
}

.rbt-input-hint{
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    left: 0px;
    pointer-events: none;
    position: absolute;
    top: 0px;
    width: 100%;
    border-style: solid;
    border-width: 0px;
    font-size: 16px;
    color:#fff!important;
    opacity:1!important;
    height: 50px;
    margin: 2px 0px;
}

.form-control {
  display: block;
  width: 100%;
  height:50px;
  padding: 5px 0 5px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: #fff!important;
  opacity:1;
  background-color: #0B1A40;
  background-clip: padding-box;
  border: 0px solid #0B1A40;

  -webkit-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus{
  color:#fff;
  background:#F12BC5;
  border:0;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9C9FC3;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #9C9FC3;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #9C9FC3;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #fff;
  text-align: left;
  list-style: none;
  background-color: #102760;
  background-clip: padding-box;
  border: 1px solid #F12BC5;
  border-bottom-left-radius: 13px;
  border-top-left-radius:0px;

}

.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-bottom:1px solid #0B1A40;
}

.dropdown-item:hover {
  background:#F12BC5;
  color:#fff;
}
/*-----------*/

/*-----------   LOGIN   -----------------------------------------------*/

.login{
  margin:20% auto;
}

.login-container {
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin:20% 0;
  border:1px solid red;
}


.logo-container {
  text-align: center;
  width:100%;
}
    .logo{
      width:100px;
      height:100px;
      margin:0 auto 20px;
      background: transparent url("../images/dealershare_logo-D_sm.svg")no-repeat top center;

    }
    .logo-container h1{text-align:center; margin:0 auto; line-height:0.8;}

.form-container {
  padding: 16px;
  width:100%;
  max-width:400px;
  margin:0 auto;
}

/*-----------*/


/*-----------  NAVIGATION BAR -------------------------------------------------*/


.nav-bar{
  background:#0B1A40;
  border-radius:30px;
  margin:20px;
  padding:20px 5px;
  }

  .logo-sm{
    width:50px;
    height:50px;
    margin:0 auto 50px;
    background: transparent url("../images/dealershare_logo-D_sm.svg")no-repeat top center;
    display:block;
  }


  .nav-bar input{
    margin:0;
    padding:5px 10px;
    background: #0B1A40;
    color:#9C9FC3;
    border:none;
    text-transform:uppercase;
    font-size:14px;
  }
  .nav-bar input:hover{
    background:#fff;
  }
 


/*-----------   SEARCH   -----------------------------------------------*/

.search {
  background: #0B1A40;
  padding-top: 20px;
  max-width: 250px;
  height: 100vh;
}

.search-box {
  padding: 0px;
}




/*------------ RESULTS ------------  */


#results {
  float: left;
  width: 70%;
}

.loader{
  background: transparent url('../images/loading.svg') no-repeat center center;
  width:80px;
  height:385px; 
  margin:0 auto;
}


  .grid {
  /* Grid Fallback */
  display: flex;
  flex-wrap: wrap;
  
  align-content:flex-start;
  justify-content:space-around;
  grid-column-gap:2%;
  /* Supports Grid 
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-auto-rows: minmax(300px, auto);
  grid-column-gap: 20px;
  grid-row-gap: 20px;*/
  }

.item {
  border: 2px solid #fff;
  background: #fff;
  padding: 0px;
  margin:1% 0;
  border-radius:30px;
  transition: all 0.2s ease-in-out;
  min-width:260px;
  width:22%;
  
  position:relative;
}

.item .gallery-image{
  background:#fff;
  border-bottom:1px solid #F12BC5;
  transition: all 0.2s ease-in-out;
  height:180px;
  cursor:pointer; 
  /*webkit-clip-path: polygon(89% 0, 95% 2%, 99% 5%, 100% 8%, 100% 100%, 0 100%, 0 10%, 3% 4%, 8% 1%, 12% 0);
  clip-path: polygon(
  85% 0; 86% 1%, 89% 2%, 90% 3%, 91% 4%, 92% 5%, 93% 6%, 94% 7%, 95% 8%, 96% 9%, 97% 10%, 98% 11%, 99% 40%, 100% 15%,
  
  100% 100%, 0 100%, 
  
  0% 11%, 1% 10%, 2% 8%, 3% 6%, 4% 4%, 5% 3%);*/
  clip-path: inset(2px 2px round 30px 30px 0 0);
}
.item .gallery-image:hover {border-bottom:1px solid #0B1A40;}


.info {
  text-align: left;
  padding: 10px;
  color:#282828;
}

.info .vehicle-title{
  font-size:14px;
  font-weight:700;
}

.info .year {
  display: inline-block;
  font-size: 14px;
}
.info .make {
  display: inline-block;
  font-size: 14px;
}
.info .model {
  display: block;
  font-weight: 400;
  font-size: 18px;
}

.info .numbers{
  display:flex;
  justify-content: space-between;
  margin:0px 0;
  }
  .info .title {
    color:#9C9FC3;
    font-size: 12px;
    padding-bottom:5px;
    border-bottom:1px solid #9C9FC3;
  }

.info .detail{
  width:30%;
  
  }
.info .miles {
  display: inline-block;
  font-size: 18px;
  padding-right:10px;
  width:50%;
}
.info .distance {
  display: inline-block;
  font-size: 18px;
  width:25%;
}
.info .days {
  display: inline-block;
  font-size: 18px;
  width:25%;
}


.item .dealer {
  padding: 0px 0 2px;

}
.info .vin {
  font-weight: 300;
  font-size:12px;
  line-height: 1.5;
  color:#999;
}

.item .cta{
  border-radius:30px;
  background-image: linear-gradient(
    to left top,
    #F11270,
    #F12BC5);
    padding:15px 15px 10px;
}

.cta .pricing{
  display:flex; 
  justify-content: space-between;
  margin:0 0 0px;
  height:30px;
  
}
.cta .price {
  font-weight: 700;
  font-size: 30px;
  letter-spacing:-1px;
  line-height:1;
  flex:2;
}
.price-change{
  flex:1;
  padding-top:8px;
  line-height:1;
  text-align:center;
}

.actions{
  display:flex; 
  justify-content: flex-start;
  gap:5%;
}
.cta .view{
  flex:1;
  margin-left:0px;
}
.cta .link{flex:1;}




.results-footer {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  border-top: 1px solid #444;
  margin: 1% auto;
}

.results-footer button{
  /*flex:45%;*/
  text-transform: uppercase;
    margin:1%;
    
  
}


/*----------------------  MODAL -----------------------*/
.modal-backdrop.show{opacity:0.8;}

.modal-dialog{
  width:80%;
  max-width:1200px;
}
.model-lg{
  background:#fff;
  color:#686977;
}

.modal-content {
  position: relative;
  /*display: flex;
  flex-direction: column;*/
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #fff;
  border-radius: 30px;
  outline: 0;
  padding:30px;
}
/*------- MODAL VEHICLE HEADER ------------------------*/

.modal-header{
  height:80px;
  min-height:50px;
  display:flex;
  justify-content:space-between;
  padding:0;
  color:#0B1A40!important;
  border:0;
  
}

.header-title{width:100%;}

.modal-title{
  width:100%;
  font-weight:700;
}

/*------- MODAL VEHICLE TOP ------------------------*/
.modal-body{padding:0;}

.top{
  background:#fff; 
  background-image: linear-gradient(
    to left top,
    #F11270,
    #F12BC5);
  display: flex; 
  flex-wrap:wrap;
  justify-content: space-between;
  flex-direction:row;
  margin:0;
  padding:0px;
  list-style:none;
  border-radius:30px;
}

.modal .gallery-image{
  padding:0px;
  width:100%;
}

/*------- MODAL VEHICLE GALLERY ------------------------*/
.carousel{
  width:50%;

}
.carousel-inner{
  border-top-left-radius:30px;
  border-bottom-left-radius:30px;
}
.carousel-item{
  width:100%; 
  object-fit:cover;
}
.carousel-item img{margin:0px auto; width:100%;}
/*.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}*/


/*------- MODAL VEHICLE INFO ------------------------*/
.vehicle-info{
  
  padding:20px;
  width:50%;
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
  flex-direction:row;
  position:relative;
 
}

.price{
  flex:2;
  margin:0 10px 20px 0;

}

.price-history{
  font-size:14px;
  line-height:1;
  margin:7px 0;
  padding:3px 0;
  flex:1;
}
.price-history{}
.price-history span{padding:4px 0;display:block;}

.vehicle-info .title{
    padding-bottom:4px;
    border-bottom:1px solid #fff; 
    margin-bottom:5px;
  }
.numbers {
  display:flex;
  justify-content:space-between;
  width:100%;
  margin:0;
}
.numbers .detail{margin-bottom:0px;}
.amount{
  font-size:31px;
  font-weight:600;
  line-height:1.5;
}
.amount span{
  font-size:13px;
  font-style:italic;
}
.contact{
  bottom:0;
  margin:20px 0 0;
  width:100%;
}


.specs{
  background:#EDEEF5;
  border-radius:30px;
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
  color:#686977;
  padding:30px;
}

.specs h4{margin-bottom:10px;}

.list-group{
  width:45%;
  border:none;
}
.list-group-item{
  border:0;
  background:none;
  color:#161D5C;
  font-weight:700;
}



/*--------- TABS -----------------*/

.nav-tabs{
  background:#0B1A40;
  margin:30px 0;
  border-radius:30px;
  display: flex;
  flex-direction:row;
  justify-content: flex-start;

}
.nav-tabs .nav-link {
  display: block;
  margin-bottom:0;
  padding:20px 40px;
  color: #F12BC5;
  background-color: #0B1A40;
  text-decoration: none;
  border:0;
  border-radius: 30px;
}
.nav-link:hover{
  color:#fff;
  background-color: #102760;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: #F12BC5;
  border-radius:30px;
  margin:0;
}
.tab-content{width:100%;}

.vehicle-all{
  color:#686977;
  margin-top:30px;
}
.features-list{
  border-top:1px solid #9C9FC3;
  margin:10px 0;
  padding:20px 0;
}

.dealership{
  display:flex;
  justify-content:space-between;
  flex-direction:row;
  margin-bottom:30px;
  width:100%;
}
.dealer-title{
  width:50%;
  display:flex;
}


.dealer-title .logo{
  margin:0 20px 20px 0; 
  text-indent:-5000px; 
  width:60px; 
  height:60px; 
}
.dealer-title .name{width:100%;}
.dealer-title .address{display:block;}



.dealer-contact{
  display:flex;
  flex-direction:column;
  margin-left:0%;
  width:50%;
  
}
.dealer-contact button{margin:0;}

.staff{
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
}
.staff h4{
  width:100%; 
  border-bottom:1px solid #9C9FC3; 
  margin:20px 0; 
  padding-bottom:10px;
}
.person{width:300px; margin-bottom:30px;}
.person h6{margin:5px 0 10px;}

.modal-footer{ border:none;}
.modal-footer button{
  width:25%;
  margin:1%;
}


/*------------------------------------------------------------------*/







/*-------------- MOBILE  -------------------------------------------------*/


@media screen and (max-width: 1900px) {
  html {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
  }
  /*.main    {flex: 3 0px; }
  .aside   { order: 1; } 
  .main    { order: 2; }
  .footer  { order: 4; }

*/
}


@media all and (max-width: 1240px) {

  }


@media screen and (max-width: 1024px) {
  h1 {
    margin-bottom: 30px;
    font-size: 36px;
  }
  h2 {
    margin-bottom: 15px;
    font-size: 28px;
  }
  h3 {
    font-size: 21px;
  }
  h4 {font-size: 16px;}
h5 {font-size: 14px;}
h6{font-size: 10px;}
 
.dealership{
  flex-direction:column;
  margin-bottom:30px;
}
.dealer-title, .dealer-contact{width:100%; margin:0;}

.modal-dialog{
  width:100%;
}
.modal-content {
  width: 98%;
  padding:4%;
}
.carousel{width:100%;}
.vehicle-info{
  width:100%;
  flex-direction:row;
}

.modal .numbers{flex-direction:row; margin:0 0 10px;}
.vehicle-info .title{margin-bottom:0px;}
.carousel-inner{
  border-top-left-radius:30px;
  border-top-right-radius:30px;
  border-bottom-left-radius:0px;
}

}

@media screen and (max-width: 840px) {
  
  
}


@media screen and (max-width: 740px) {

  .App{
    flex-direction:column;
  }
  .login-container {
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    margin-top:10%;
  }
  
  .wrapper{
    width:96%;
    margin:80px auto;}

  .nav-bar{
    width:92%;
    height:50px;
    padding:0 10px;
    overflow:hidden;
    flex-direction:row;
  }
  .logo-sm{
    width:40px;
    height:40px;
    margin:5px auto 0;
  }

    .icon {
      margin:0px auto;
      background:#0B1A40;
      border-radius:15px;
      width:50px;
      height:50px;
      text-align:center;
      padding-top:15px;
    }
    .icon button{width:20px; height:30px;}

    

  .aside{width:100%;}
  .wrapper{flex-direction:column;}
  .main{margin:5% 0;}
  
  .item{width:40%;margin:3% 1%;}
  .item .gallery-image{height:180px;}
  .dealer-title{width:100%; }

 
}



@media screen and (max-width: 640px) {

  body {
    font-size: 87.5%;
  }


 
  .results {padding:1%; }
  .item{width:100%;}
  .item .gallery-image{height:240px;}

  .nav-tabs{
    flex-direction:row;
    justify-content:space-between;
    justify-content:flex-start;
  }
  .nav-tabs .nav-link {
    display: block;
    margin-bottom:0;
    padding:10px 30px;
  }
  .modal .numbers{flex-direction:column; margin:0 0 10px;}
}


    /*----- MIN WIDTH 

    @media all and (min-width: 576px) {
      .aside { 
        flex: 1 0px; 
     } 
       
    }

    @media all and (min-width: 768px) {
      .main    {flex: 3 0px; }
      .aside   { order: 1; } 
      .main    { order: 2; }
      .footer  { order: 4; } 
    }---*/
