html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.episodes-layout {
  display: flex;
  flex-wrap: wrap;
  min-width: 100wh;

}

.episodes-box {
  padding: 0.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
}


